import React from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { AiFillMail } from 'react-icons/ai';
import { GoLocation } from 'react-icons/go';
import { BsTelephoneFill } from 'react-icons/bs';
import emailjs from '@emailjs/browser';
import { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_gqfytqj',
        'template_vbpuebi',
        form.current,
        '2xWMX5XwpZ8kalz_E'
      )
      .then(
        (result) => {
          if (result.text === 'OK') {
            toast.success('Form Submitted Successfully');
          }
        },
        (error) => {
          if (error.text) {
            toast.error('Something went wrong');
          }
        }
      );
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
    setSubject('');
  };
  return (
    <>
      <div className='contact'>
        <Container>
          <p className='contact__us'>contact us</p>
          <h1>drop us a line</h1>
          <p className='contact__desc'>
            Fill out the form below and we will get back to you shortly. Also,
            feel free to call us or email us anytime.
          </p>
          <div className='contact__detail'>
            <div>
              <AiFillMail className='icon' />
              <span className='ms-3'>hotelpauwabutwal@gmail.com</span>
            </div>
            <div>
              <GoLocation className='icon' />
              <span className='ms-3'>Yogikuti 3, Rupandehi, Nepal </span>
            </div>
            <div>
              <BsTelephoneFill className='icon' />
              <span className='ms-3'>+977 9857072511, 071-437245</span>
            </div>
          </div>
          <div className='contact__map'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.731723286103!2d83.46036033488767!3d27.663770799999988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3996857eac033a47%3A0xb45d8cbf5d94a5d3!2sHotel%20Pauwa%20Yogikuti%20Butwal!5e0!3m2!1sen!2snp!4v1658311106271!5m2!1sen!2snp'
              allowfullscreen=''
              loading='lazy'
              referrerpolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>

          <Container>
            <ToastContainer />
            <Form ref={form} onSubmit={sendEmail}>
              <Row>
                <Col md={6}>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='text'
                      placeholder='Full Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name='name'
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='email'
                      placeholder='Email Address'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name='email'
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='number'
                      placeholder='Phone Number'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      name='phone'
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label>Subject</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='text'
                      placeholder='Subject'
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      name='subject'
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Label>Message</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      as='textarea'
                      placeholder='Type your message'
                      rows={5}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      name='message'
                    />{' '}
                  </Form.Group>
                </Col>
              </Row>
              <div style={{ textAlign: 'center' }}>
                <button className='btonn bton--lg bton--ghost' type='Submit'>
                  Send Message{' '}
                </button>
              </div>
            </Form>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default Contact;
