import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Tab, Tabs, Form, Modal } from 'react-bootstrap';
import { AiFillMail } from 'react-icons/ai';
import { GoLocation } from 'react-icons/go';
import { BsTelephoneFill } from 'react-icons/bs';
import { FaQuoteLeft } from 'react-icons/fa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useParams, Link, useNavigate } from 'react-router-dom';
import gql from 'graphql-tag';
import Query from '../../components/Query';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Branch = () => {
  const navigate = useNavigate();

  const page = 'branch';
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [eventType, setEventType] = useState('');
  const [numOfPerson, setNumOfPerson] = useState('');
  const [date, setDate] = useState('');
  const [branchName, setBranchName] = useState('');
  const [message, setMessage] = useState('');
  const [packageName, setPackageName] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const form = useRef();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const GET_BRANCH = gql`
    query NewQuery {
      branches {
        nodes {
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `;
  // const GET_OFFER = gql`
  //   query NewQuery {
  //     offers {
  //       nodes {
  //         title
  //         offers {
  //           description
  //           image {
  //             sourceUrl
  //           }
  //         }
  //       }
  //     }
  //   }
  // `;
  const { id } = useParams();
  const GET_BRANCHDESC = gql`
    query NewQuery($id: ID!) {
      branch(id: $id, idType: SLUG) {
        title
        featuredImage {
          node {
            sourceUrl
          }
        }

        branches {
          address
          tagline
          amenities {
            title
            icon {
              sourceUrl
            }
          }
          banquetHall {
            title
            description
            image {
              sourceUrl
            }
          }
          dinning {
            description
            diningArea {
              feature
            }
            featuresDinning {
              features
            }
            gallery {
              sourceUrl
            }
          }
          gallery {
            sourceUrl
          }
          highlights
          phone
          email
          roomSuites {
            description
            name
            price
            image {
              sourceUrl
            }
          }
          snapshots {
            sourceUrl
          }
          testimonials {
            reviews
            position
            name
          }
        }
      }
    }
  `;
  const enquiryForm = (name, bName) => {
    setPackageName(name);
    setBranchName(bName);
    handleShow();
  };
  const modalClose = () => {
    if (phone.length !== 10) {
      setPhoneError(true);
      return false;
    }
    if (!phone) {
      return false;
    } else {
      handleClose();
    }
  };
  const sendEmail = (e) => {
    e.preventDefault();
    if (
      name &&
      email &&
      phone.length === 10 &&
      eventType &&
      numOfPerson &&
      date &&
      packageName &&
      branchName &&
      message
    ) {
      emailjs
        .sendForm(
          'service_j5dz6eq',
          'template_kp53nwe',
          form.current,
          'bf1hpQKsdwYZtMALp'
        )
        .then(
          (result) => {
            if (result.text === 'OK') {
              toast.success('Form Submitted Successfully');
            }
          },
          (error) => {
            if (error.text) {
              toast.error('Something went wrong');
            }
          }
        );
    }
  };
  const settings = {
    infinite: true,
    dots: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingss = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ToastContainer />

      <Query query={GET_BRANCHDESC} id={id} page={page}>
        {({ data: { branch } }) => {
          return (
            <>
              <div className='contact'>
                <Container>
                  <p className='contact__us'>Outposts</p>
                  <h1>{branch?.title}</h1>
                  <p
                    className='contact__desc'
                    dangerouslySetInnerHTML={{
                      __html: branch?.branches?.tagline,
                    }}
                  />
                  <section className='text-center'>
                    <Link to='/reservationform'>
                      <button
                        className='bton bton--primary bton--lg'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/reservationform', {
                            state: {
                              checkIn: null,
                              checkOut: null,
                              selectBranch: branch?.title,
                              roomName: '',
                            },
                          });
                        }}
                      >
                        Book&nbsp;Now
                      </button>
                    </Link>
                  </section>
                  <div className='contact__detail'>
                    <div>
                      <BsTelephoneFill className='icon' />
                      <span className='ms-3'>{branch?.branches?.phone} </span>
                    </div>
                    <div>
                      <AiFillMail className='icon' />
                      <span className='ms-3'>{branch?.branches?.email}</span>
                    </div>
                    <div>
                      <GoLocation className='icon' />
                      <span className='ms-3'>{branch?.branches?.address} </span>
                    </div>
                  </div>
                </Container>
              </div>
              <div className='branch'>
                <Container>
                  <div className='branch__banner'>
                    <img src={branch?.featuredImage?.node?.sourceUrl} alt='' />
                  </div>
                </Container>
              </div>
              <div className='contact'>
                <div
                  style={{ background: '#F9F6EE', padding: '10px 0 40px 0' }}
                >
                  <Container>
                    <p className='contact__us'>Features</p>
                    <h1>Our highlights</h1>
                    <p
                      className='contact__desc'
                      style={{ width: '75%', margin: '35px auto 0 auto' }}
                      dangerouslySetInnerHTML={{
                        __html: branch?.branches?.highlights,
                      }}
                    />

                    <div className='contact__slider'>
                      <Row className='gx-2'>
                        {branch?.branches?.gallery?.length > 3 ? (
                          <>
                            {branch?.branches?.gallery?.map((a) => {
                              return (
                                <Slider {...settings}>
                                  <Col md={3}>
                                    <img src={a?.sourceUrl} alt='' />
                                  </Col>
                                </Slider>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <Row className='gx-2'>
                              {branch?.branches?.gallery?.map((a) => {
                                return (
                                  <Col md={3}>
                                    <img src={a?.sourceUrl} alt='' />
                                  </Col>
                                );
                              })}
                            </Row>
                          </>
                        )}
                      </Row>
                    </div>
                  </Container>
                </div>
              </div>
              <div className='branchestab'>
                <Container>
                  <Tabs
                    defaultActiveKey='amenities'
                    id='uncontrolled-tab-example'
                    className='mb-5'
                  >
                    <Tab eventKey='amenities' title='Amenities'>
                      <Row>
                        {branch?.branches?.amenities?.map((a) => {
                          return (
                            <Col md={2} sm={6} xs={6} className='mt-4'>
                              <section className='animitiesBox'>
                                <img
                                  src={a?.icon?.sourceUrl}
                                  alt=''
                                  style={{ height: '70px' }}
                                />
                                <div className='animitiesBox-title'>
                                  {a.title}
                                </div>
                              </section>
                            </Col>
                          );
                        })}
                      </Row>
                    </Tab>
                    <Tab eventKey='Rooms & Suites' title='Rooms & Suites'>
                      <div className='roomdesc'>
                        <div className='roomdesc__description'>
                          {branch?.branches?.roomSuites?.map((a) => {
                            return (
                              <>
                                <Row className='gx-3 mb-5'>
                                  <Col md={6}>
                                    <div>
                                      <img src={a?.image?.sourceUrl} alt='' />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className='roomdesc__description--leftbox'>
                                      <div className='roomdesc__description--leftbox--heading'>
                                        <p>{a.name}</p>
                                        {/* <p>Rs.{a?.price}</p> */}
                                      </div>
                                      <div className='roomdesc__description--leftbox--about'>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: a?.description,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="roomdesc">
                        <div className="roomdesc__description">
                          <Row className="gx-3">
                            <Col md={6}>
                              <div>
                                <img src={BranchBanner} alt="" />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="roomdesc__description--leftbox">
                                <div className="roomdesc__description--leftbox--heading">
                                  <p>Deluxe Honeymoon Room</p>
                                  <p>Rs.2500/Night</p>
                                </div>
                                <div className="roomdesc__description--leftbox--about">
                                  <p>
                                    Facilisis rhoncus id phasellus massa urna,
                                    diam volutpat lectus a. Enim duis cras
                                    ullamcorper rhoncus diam faucibus. Quisque
                                    enim morbi faucibus ultrices bibendum id.
                                    Odio mattis ultrices tellus et orci turpis
                                    augue. Aliquet tellus sit elit turpis in
                                    gravida. Non nullam senectus in iaculis
                                    faucibus. Fusce non donec euismod nam
                                    elementum lacus et. Nisl phasellus turpis
                                    aenean donec dui.
                                  </p>
                                </div>
                                <div className="roomdesc__description--leftbox--facility">
                                  <p>Rooms Facilities</p>
                                  <ul>
                                    <li>Flat screen TV</li>
                                    <li>Kettle, tea & Coffee</li>
                                    <li>Breakfast included</li>
                                    <li>Shower-bathtub combination</li>
                                    <li>Hairdryer</li>
                                    <li>Telephone</li>
                                    <li>Towel warmer</li>
                                    <li>
                                      Fresh mineral water and fresh fruits
                                    </li>
                                  </ul>
                                </div>
                                <button className="bton bton--md bton--ghost">
                                  Book Now
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div> */}
                    </Tab>
                    <Tab eventKey='Dining' title='Dining'>
                      <div className='branchestab__dining'>
                        <Row className='gy-5'>
                          {branch?.branches?.dinning?.gallery?.map((a) => {
                            return (
                              <Col md={4}>
                                <div>
                                  <img src={a.sourceUrl} alt='' />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                        <p
                          className='branchestab__dining--desc'
                          dangerouslySetInnerHTML={{
                            __html: branch?.branches?.dinning?.description,
                          }}
                        />

                        <Row>
                          <Col md={4}>
                            <div className='branchestab__dining--left'>
                              <p>Dining Area</p>
                              <ul>
                                {branch?.branches?.dinning?.diningArea?.map(
                                  (a) => {
                                    return <li>{a.feature}</li>;
                                  }
                                )}
                              </ul>
                            </div>
                          </Col>
                          <Col md={8}>
                            <div className='branchestab__dining--right'>
                              <p>Features of Our Dining Area</p>
                              <ul>
                                {branch?.branches?.dinning?.featuresDinning?.map(
                                  (a) => {
                                    return <li>{a.features}</li>;
                                  }
                                )}
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                    <Tab eventKey='Banquet Hall' title='Facilities'>
                      <Row>
                        {branch?.branches?.banquetHall?.map((a) => {
                          return (
                            <Col md={4}>
                              <div className='branchestab__banquethall'>
                                <section
                                  className='branchCard '
                                  style={{ background: 'white' }}
                                >
                                  <figure className='branchCard-image'>
                                    <img src={a.image?.sourceUrl} alt='' />
                                  </figure>
                                  <h2 className='branchCard-title'>
                                    {a?.title}
                                  </h2>

                                  <p
                                    className='branchCard-paragraph'
                                    dangerouslySetInnerHTML={{
                                      __html: a?.description,
                                    }}
                                  />

                                  <section className='text-center'>
                                    <Link
                                      to=''
                                      className='bton bton--md bton--ghost'
                                      onClick={() =>
                                        enquiryForm(a?.title, branch?.title)
                                      }
                                    >
                                      Make an Enquiry{' '}
                                    </Link>
                                  </section>
                                </section>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </Tab>
                    <Tab eventKey='Reviews' title='Reviews'>
                      <Row className='gx-5'>
                        {branch?.branches?.testimonials?.length > 2 ? (
                          <>
                            <Slider {...settingss}>
                              {branch?.branches?.testimonials?.map((a) => {
                                return (
                                  <Col md={6}>
                                    <div className='branchestab__reviews'>
                                      <FaQuoteLeft className='branchestab__reviews--icon' />
                                      <p
                                        className='branchestab__reviews--review'
                                        dangerouslySetInnerHTML={{
                                          __html: a?.reviews,
                                        }}
                                      ></p>
                                      <h5>{a?.name}</h5>
                                      <p className='branchestab__reviews--post'>
                                        {a?.position}
                                      </p>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Slider>
                          </>
                        ) : (
                          <>
                            <Row className='gx-5'>
                              {branch?.branches?.testimonials?.map((a) => {
                                return (
                                  <Col md={6}>
                                    <div className='branchestab__reviews'>
                                      <FaQuoteLeft className='branchestab__reviews--icon' />
                                      <p
                                        className='branchestab__reviews--review'
                                        dangerouslySetInnerHTML={{
                                          __html: a?.reviews,
                                        }}
                                      ></p>
                                      <h5>{a?.name}</h5>
                                      <p className='branchestab__reviews--post'>
                                        {a?.position}
                                      </p>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </>
                        )}
                      </Row>
                    </Tab>
                    <Tab eventKey='View Snapshots' title='View Snapshots'>
                      <div className='branchestab__photo'>
                        <Row>
                          {branch?.branches?.snapshots?.map((a) => {
                            return (
                              <Col md={3}>
                                <figure>
                                  <img src={a?.sourceUrl} alt='' />
                                </figure>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </Tab>
                  </Tabs>
                </Container>
              </div>
            </>
          );
        }}
      </Query>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Make an Inquiry</Modal.Title>
        </Modal.Header>
        <Form onSubmit={sendEmail} ref={form}>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <div className='personal-info'>
                  <p>personal Information</p>
                  <Form.Label>Name</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='text'
                      placeholder='Enter your fullname'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name='name'
                      required
                    />
                  </Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='email'
                      placeholder='Enter your email address'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name='email'
                      required
                    />
                  </Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Group
                    // className="mb-4"
                    controlId='formBasicEmail'
                  >
                    <Form.Control
                      type='number'
                      placeholder='Enter your phone number'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      name='phone'
                      required
                    />
                  </Form.Group>
                  {phoneError && phone.length !== 10 && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: 'red',
                      }}
                    >
                      Phone Number must be 10 digits.
                    </p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className='personal-info'>
                  <p>Event Information</p>
                  <Form.Label>Event Type</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='text'
                      placeholder='Eg:meeting & Conference'
                      value={eventType}
                      onChange={(e) => setEventType(e.target.value)}
                      name='eventType'
                      required
                    />
                  </Form.Group>
                  <Form.Label>Number of Persons</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='number'
                      placeholder='Total guests'
                      value={numOfPerson}
                      onChange={(e) => setNumOfPerson(e.target.value)}
                      name='numOfPerson'
                      required
                    />
                  </Form.Group>
                  <Form.Label>Date and Time</Form.Label>
                  <Form.Group className='mb-4' controlId='formBasicEmail'>
                    <Form.Control
                      type='text'
                      placeholder='Eg:2020/02/02 4:00 PM'
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      name='date'
                      required
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <Form.Label>Packages Name</Form.Label>
                <Form.Group className='mb-4' controlId='formBasicEmail'>
                  <Form.Control
                    type='input'
                    placeholder='Package Name'
                    value={packageName}
                    onChange={(e) => setPackageName(e.target.value)}
                    name='packageName'
                    required
                  />{' '}
                </Form.Group>
              </Col>
              <Query query={GET_BRANCH}>
                {({ data: { branches } }) => {
                  return (
                    <>
                      <>
                        <div className='mb-3'>
                          <Form.Label>Select Branch</Form.Label>

                          <Form.Select
                            aria-label='Default select example'
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                            name='branch'
                            required
                          >
                            {branches.nodes?.map((a) => {
                              return <option value={a.title}>{a.title}</option>;
                            })}
                          </Form.Select>
                        </div>
                      </>
                    </>
                  );
                }}
              </Query>
              <Col md={12}>
                <Form.Label>Message</Form.Label>
                <Form.Group className='mb-4' controlId='formBasicEmail'>
                  <Form.Control
                    as='textarea'
                    placeholder='Type your message'
                    rows={5}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    name='message'
                    required
                  />{' '}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={modalClose}
              className='bton bton--md bton--ghost'
              type='submit'
            >
              Make Enquiry
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Branch;
