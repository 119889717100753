import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TitleBlock from '../TitleBlock';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import Query from '../../components/Query';
const Branches = () => {
  const GET_BRANCH = gql`
    query NewQuery {
      branches {
        nodes {
          title
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          branches {
            description
          }
        }
      }
    }
  `;
  const rot = ['fsda', 'sdf', 'as'];
  return (
    <div>
      <TitleBlock
        number='02'
        pretitle='outposts'
        title='visit our branches'
        desc=' Hotel Pauwa offers typical Nepalese traditional cultural hospitality in a homely environment. We provide services from 11 locations throughout Nepal.'
      />
      <Query query={GET_BRANCH}>
        {({ data: { branches } }) => {
          return (
            <Container>
              <Row>
                {branches?.nodes?.map((m) => (
                  <Col sm={6} lg={4}>
                    <section className='branchCard'>
                      <figure className='branchCard-image'>
                        <img src={m.featuredImage?.node?.sourceUrl} alt='' />
                      </figure>
                      <h2 className='branchCard-title'>{m?.title}</h2>

                      <p
                        className='branchCard-paragraph'
                        dangerouslySetInnerHTML={{
                          __html: m.branches?.description,
                        }}
                      />

                      <section className='text-center'>
                        <Link
                          to={`/branch/${m?.slug}`}
                          className='bton bton--md bton--ghost'
                        >
                          Discover More
                        </Link>
                      </section>
                    </section>
                  </Col>
                ))}
              </Row>
            </Container>
          );
        }}
      </Query>
    </div>
  );
};

export default Branches;
