import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import Branch from '../Branch';
import Contact from '../Contact';
import Gallery from '../Gallery';

import Home from '../Home';
import Offer from '../Offer';
import ReservationForm from '../ReservationForm';
import Resort from '../Resort';
import RoomDescription from '../RoomDescription';
import Rooms from '../Rooms';
import Sweets from '../Sweets';

const App = () => {
  // const [checkIn,setCheckIn]=useState();
  // const [checkOut,setCheckOut]=useState();
  // const [selectBranch,setSelectBranch]=useState();
  // const [selectRoom,setSelectRoom]=useState();
  return (
    <>
      <Navbar />

      <main>
        <section className='contentWrapper'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/offer' element={<Offer />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/rooms' element={<Rooms />} />
            <Route path='/roomdescription/:id' element={<RoomDescription />} />
            <Route path='/branch/:id' element={<Branch />} />
            <Route path='/resort/:id' element={<Resort />} />
            <Route path='/sweet/:id' element={<Sweets />} />
            <Route path='/reservationform' element={<ReservationForm />} />
          </Routes>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default App;
