import React from 'react';
import { Container } from 'react-bootstrap';

const Intro = () => {
  return (
    <div>
      <section className='introContainer'>
        <Container>
          <section className='introHolder'>
            <section className='introHolder-title'>
              01 <div className='line'></div> WELCOME
            </section>
            <section className='introHolder-slogan'>
              जहाँबाट आतिथ्यको सुरुवात हुन्छ...
            </section>
            <p className='introHolder-desc'>
              Our ensemble of unique luxury hotel still embodies the value we
              embraced from the birthplace of Lord Buddha – warm hospitality and
              refined luxury under one welcoming roof. We quietly stand out from
              the crowd at Pauwa in culture, quality, environment, and
              character.
            </p>
          </section>
        </Container>
      </section>
    </div>
  );
};

export default Intro;
