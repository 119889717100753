import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Container, Placeholder } from 'react-bootstrap';
const Query = ({ children, query, id, page }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { id: id },
  });

  if (loading)
    return (
      <>
        {page === 'booknow' && (
          <section>
            <Placeholder animation='glow'>
              <Placeholder
                style={{
                  width: '100%',
                  height: '2rem',
                }}
              />
            </Placeholder>
          </section>
        )}
        {page === 'slider' && (
          <section>
            <Placeholder animation='glow'>
              <Placeholder
                style={{
                  width: '100%',
                  height: '72vh',
                  marginBottom: '2rem',
                }}
              />
            </Placeholder>
          </section>
        )}
        {(page === 'branch' || page === 'resort') && (
          <Container>
            <section className='placeholderHolder'>
              <Placeholder animation='glow'>
                <section className='text-center mt-5'>
                  <Placeholder
                    style={{
                      width: '50%',
                      height: '3rem',
                      marginBottom: '2rem',
                    }}
                  />
                  <Placeholder
                    style={{
                      width: '80%',
                      height: '3rem',
                      marginBottom: '2rem',
                    }}
                  />
                  <Placeholder
                    style={{
                      width: '25%',
                      height: '3rem',
                      marginBottom: '2rem',
                    }}
                  />
                  <Placeholder
                    style={{
                      width: '60%',
                      height: '3rem',
                      marginBottom: '2rem',
                    }}
                  />
                </section>{' '}
                <Placeholder
                  style={{
                    width: '100%',
                    height: '3rem',
                    marginBottom: '2rem',
                  }}
                />
                <Placeholder
                  style={{
                    width: '100%',
                    height: '3rem',
                    marginBottom: '2rem',
                  }}
                />
                <Placeholder
                  style={{
                    width: '100%',
                    height: '3rem',
                    marginBottom: '2rem',
                  }}
                />
                <Placeholder
                  style={{
                    width: '100%',
                    height: '3rem',
                    marginBottom: '2rem',
                  }}
                />
              </Placeholder>
            </section>{' '}
          </Container>
        )}
      </>
    );

  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data });
};

export default Query;
